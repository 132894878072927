var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('div',{staticClass:"footer"},[_c('div',{staticClass:"foot-info"},[_c('div',{staticClass:"layout"},[_vm._m(0),_c('div',{staticClass:"foot-box clearfix"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"foot-sns"},[_c('div',{staticClass:"bdsharebuttonbox bdshare-button-style0-16",attrs:{"data-bd-bind":"1727142296637"}},[_c('a',{staticClass:"bds_weixin",attrs:{"data-cmd":"weixin","title":"分享到微信"},on:{"click":_vm.shareToWeChat}}),_c('a',{staticClass:"popup_sqq",attrs:{"href":"#","data-cmd":"sqq","title":"分享到QQ好友"},on:{"click":_vm.shareToQQ}}),_c('a',{staticClass:"bds_tsina",attrs:{"href":"#","data-cmd":"tsina","title":"分享到新浪微博"},on:{"click":_vm.shareToMicroblog}})])]),_vm._m(3)])])]),_vm._m(4),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}],staticClass:"bd_weixin_popup"},[_c('div',{staticClass:"bd_weixin_popup_head"},[_c('span',[_vm._v("分享到微信朋友圈")]),_c('a',{staticClass:"bd_weixin_popup_close",on:{"click":function($event){_vm.isShow = false}}},[_vm._v("×")])]),_c('canvas',{attrs:{"id":"QRCode_header"}}),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot-text"},[_c('h2',{staticClass:"h2"},[_vm._v("联系我们"),_c('span',[_vm._v("CONTACT US")]),_c('span',{staticStyle:{"color":"#fff"}},[_vm._v("广州市景翔信息科技有限公司")])]),_c('h3',{staticClass:"h3"},[_vm._v("服务热线："),_c('span',[_vm._v("020-39984322")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot-add"},[_c('p',[_vm._v("地址：广州市番禺区洛溪新城105国道97号东基商务大楼6楼")]),_c('p',[_vm._v("电话：020-39984322 / 39984311")]),_c('p',[_vm._v("传真：020-39984300")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot-tel"},[_c('p',[_vm._v("手机：13560068664（微信同号）")]),_c('p',[_vm._v("E-mail：mail@jeansawn.com")]),_c('p',[_vm._v("网址：http://www.jeansawn.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot-ewm"},[_c('img',{attrs:{"alt":"扫一扫关注我们","src":require("../../../assets/images/QuickMark.png")}}),_c('p',[_vm._v("扫一扫关注我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot-copy"},[_c('div',{staticClass:"layout"},[_c('p',[_vm._v(" http://www.jeansawn.com 广州市景翔信息科技有限公司 "),_c('a',{attrs:{"href":"https://beian.miit.gov.cn","target":"_blank"}},[_vm._v("粤ICP备2021114949号")]),_vm._v(" ©2024 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bd_weixin_popup_foot"},[_vm._v(" 打开微信，点击底部的“发现”，"),_c('br'),_vm._v("使用“扫一扫”即可将网页分享至朋友圈。 ")])
}]

export { render, staticRenderFns }